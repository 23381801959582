<template>
   <div>
      <v-app-bar id="home-app-bar" app color="white" elevation="1" height="80">
         <base-img v-if="$store.state.setting.global.Logo" :src="setUrlFromImage($store.state.setting.global.Logo)" class="hidden-xs-only" contain max-width="56" width="100%" @error="$store.state.setting.global.Logo = ''" />
         <base-img v-else :src="require('@/assets/logo.svg')" class="mr-3" contain max-width="46" width="100%" style="filter: brightness(1) invert(1);" />
         <v-list-item-content class="ml-2">
            <v-list-item-title class="font-weight-bold">
               {{ `${$store.state.setting.global.name} — ${$store.state.setting.global.title}` }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="$store.state.user.id">
               {{ `${$store.state.user.name} ( ${$store.state.user.code} )` }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
               Click help <a :href="manual" target="_blank">here</a> to view documentation manual
            </v-list-item-subtitle>
         </v-list-item-content>
         <v-spacer />
         <div v-if="$store.getters.isLoggedIn">
            <v-tabs class="hidden-sm-and-down" optional background-color="transparent">
               <v-tab v-for="(category, idx) in categories" v-show="Object.keys($store.state.user).some(s => category.auth.includes(s))" :key="idx"
                  :to="category.link" :exact="category.link === 'home'"
                  :ripple="false" class="font-weight-bold" min-width="96" text
                  >
                  {{ category.text }}
               </v-tab>
            </v-tabs>
         </div>
         <v-menu v-if="$store.getters.isLoggedIn" left bottom>
            <template v-slot:activator="{ on, attrs }">
               <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
               </v-btn>
            </template>
            <v-list>
               <template v-for="(menu, idx) in menus">
                  <v-divider v-if="menu.divider" :key="idx" inset class="mx-0" style="max-width: 100%;" />
                  <v-list-item v-else v-show="!menu.auth || Object.keys($store.state.user).some(s => menu.auth.includes(s))" :key="idx" @click="command(menu)">
                     <v-list-item-icon>
                        <v-icon>{{ menu.icon }}</v-icon>
                     </v-list-item-icon>
                     <v-list-item-title>{{ menu.text }}</v-list-item-title>
                  </v-list-item>
               </template>
            </v-list>
         </v-menu>
         <!-- <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" /> -->
      </v-app-bar>
      <!-- <home-drawer v-model="drawer" :items="categories" /> -->
   </div>
</template>

<script>
import { imageFromUrl } from '@/utils/nirtara'
export default {
   name: 'HomeAppBar',

   components: {
      // HomeDrawer: () => import('./Drawer'),
   },

   data: () => ({
      drawer: null,
      categories: [
         { text: 'Persons', link: '/persons', auth: ['member', 'receptionist'] },
         { text: 'Locations', link: '/locations', auth: ['building', 'floor', 'room'] },
         { text: 'Subjects', link: '/subjects', auth: ['organization'] },
         // { text: 'Options', link: '/options', auth: [] },
         { text: 'Activities', link: '/activities', auth: ['approval', 'invitation'] },
      ],
      menus: [
         { text: 'Profile', icon: 'mdi-account', link: '/profile' },
         { text: 'Settings', icon: 'mdi-hammer-screwdriver', link: '/settings', auth: ['setting'] },
         { divider: true },
         { text: 'Logout', icon: 'mdi-logout', link: '/' },
      ],
      manual: `${location.protocol}//${location.hostname}:${location.protocol === 'https:' ? 8155 : 8150}`,
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
   },
   created () {
   },
   methods: {
      command (menu) {
         switch (menu.text) {
            default: this.$route.path !== menu.link && this.$router.push({ path: menu.link })
               break
            case 'Logout':
               this.$store.dispatch('logout')
               .then(() => {
                  this.$route.path !== menu.link && this.$router.push({ path: menu.link })
               })
               break
         }
      },
   },
}
</script>

<style lang="sass">
#home-app-bar
   .v-tabs-slider
      max-width: 24px
      margin: 0 auto

   .v-tab
      &::before
         display: none
</style>

<style lang="scss" scoped>
.v-application--is-ltr .v-list-item__icon:first-child {
   margin-right: 16px;
}
</style>
